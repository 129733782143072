import styled, { keyframes } from 'styled-components';
import { BoxContainer } from '../Container';
import { List as StyledList, Col } from '../Ant';

export const List = styled(StyledList)`
  font-size: 20px;
  .ant-avatar {
    margin-right: 0.5em;
  }
  .count {
    float: right;
  }
  .grey {
    margin-left: 0.5em;
  }
  .ant-list-item {
    border-bottom: none;
  }
`;
export const CTA = styled.div`
  margin-top: 2em;
  text-align: center;
  button {
    box-shadow: ${props => props.theme.boxShadow.button};
  }
`;

export const Map = styled.img`
  width: 100%;
  border-top-left-radius: ${props => props.theme.borderRadius.md};
  border-top-right-radius: ${props => props.theme.borderRadius.md};
  z-index: 2;
  position: relative;
`;

export const CheckIn = styled(BoxContainer)`
  display: flex;
  text-align: left;
  width: 100%;
  background-color: ${props => props.theme.bg.default};
  align-items: center;
  padding: 0.5em 1em;
  z-index: 2;
  line-height: 18px;
  .ant-avatar {
    margin-left: auto;
  }
  div > div:nth-child(1) {
    color: black;
  }
  div > div:nth-child(2) {
    color: grey;
  }
`;

export const DottedLine = styled.div`
  width: calc(50% - 14px);
  border-right: 4px dotted grey;
  height: calc(100% - 28px);
  position: absolute;
  z-index: 1;
`;

export const CarImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SecondCol = styled(Col)`
  @media (max-width: 575px) {
    margin-top: 6em;
    margin-bottom: 6em;
  }
`;
const shakeBreaks = keyframes`
  0% { transform: translate(2px, 1px) rotate(0deg); }
  2% { transform: translate(-1px, -2px) rotate(-1deg); }
  4% { transform: translate(-3px, 0px) rotate(1deg); }
  6% { transform: translate(0px, 2px) rotate(0deg); }
  8% { transform: translate(1px, -1px) rotate(1deg); }
  10% { transform: translate(-1px, 2px) rotate(-1deg); }
  12% { transform: translate(-3px, 1px) rotate(0deg); }
  14% { transform: translate(2px, 1px) rotate(-1deg); }
  16% { transform: translate(-1px, -1px) rotate(1deg); }
  18% { transform: translate(2px, 2px) rotate(0deg); }
  20% { transform: translate(1px, -2px) rotate(-1deg); }
  22% { transform: translate(0) rotate(0deg); }
`;

const shake = keyframes`
  0% { transform: translate(2px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(0px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(2px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(2px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

export const Shaking = styled.div`
  animation-name: ${shakeBreaks};
  animation-duration: 4s;
  transform-origin: 50% 50%;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  &:hover {
    animation-name: ${shake};
    animation-duration: 0.8s;
  }
`;
const topToBottom = keyframes`
 
	0% { opacity: 0; }
	7.5% { opacity: 0; transform: translateY(50px); }
	10% { opacity: 1; transform: translateY(0px); }
	27.5% { opacity: 1; transform: translateY(0px); }
	30% { opacity: 0; transform: translateY(-50px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
`;

export const GamifyType = styled.div`
  overflow: hidden;
  position: relative;
  color: ${props => props.theme.color.greyText};
  & > div {
    display: flex;
    align-items: center;
    height: 24px;
    width: 100%;
    justify-content: flex-end;
    & > div {
      animation: ${topToBottom} 20s linear infinite 0s;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      text-align: left;
      &:nth-child(2) {
        animation-delay: 4s;
      }
      &:nth-child(3) {
        animation-delay: 8s;
      }
      &:nth-child(4) {
        animation-delay: 12s;
      }
      &:nth-child(5) {
        animation-delay: 16s;
      }
    }
  }
`;

export const Mention = styled.div`
  .cover {
    width: 100%;
    height: 200px;
    background: #c5d2d9 no-repeat 50%;
    -o-object-fit: cover;
    object-fit: cover;
    border-top-left-radius: ${props => props.theme.borderRadius.md};
    border-top-right-radius: ${props => props.theme.borderRadius.md};
  }
  .body {
    padding: 1.5em;
  }
  .ant-typography {
    text-align: left;
    color: ${props => props.theme.color.tertiary};
  }
  .interactive {
    svg {
      margin-right: 0.5em;
    }
    position: absolute;
    right: -1em;
    top: -1em;
    color: white;
    background-color: #faad14;
    border-radius: ${props => props.theme.borderRadius.md};
    padding: 4px 8px;
  }

  .author {
    color: #738a94;
  }

  .reading-time {
    float: right;
    color: #738a94;
  }

  width: 100%;
  position: relative;
  border-radius: ${props => props.theme.borderRadius.md};
  display: flex;
  flex: 1 1 300px;
  flex-direction: column;
  min-height: 300px;
  background: #fff 50%;
  background-size: cover;
  box-shadow: 8px 14px 38px rgba(39, 44, 49, 0.06), 1px 3px 8px rgba(39, 44, 49, 0.03);
  transition: all 0.5s ease;
  &:hover {
    transform: translate3D(0, -1px, 0) scale(1.02);
  }
`;
