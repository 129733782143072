/* eslint-disable no-unused-vars */
import dynamic from 'next/dynamic';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Button, Col, Row, Title } from '../components/Ant';
import { BoxContainer, Container, FluidContainer, Section } from '../components/Container';
import {
  CheckIn,
  CTA,
  DottedLine,
  GamifyType,
  List,
  Map,
  SecondCol,
  Shaking,
} from '../components/Homepage';
import Layout from '../components/Layout';
import { Store } from '../store';
import rgTheme from '../styles/rgTheme';

const BrowseDestinationsWidget = dynamic(() => import('../components/BrowseDestinations/Widget'));
const Feed = dynamic(() => import('../components/Homepage/Feed'));

const Index = () => {
  const authState = useSelector(Store.auth.selectors.get);
  const isAuthenticated = authState.isAuthenticated;
  return (
    <Layout smallHeader={true}>
      {/* <div style={{ width: '100%', backgroundColor: '#F1D5A5', textAlign: 'center', position: 'fixed', top: 50, padding: '6px', height: '40px', zIndex: 9999 }}>
        <h3 style={{ color: '#826041' }}>We're live on Product Hunt right now ✨ <a style={{ color: '#000', textTransform: 'underline' }} href="https://www.producthunt.com/posts/roadgoat" target="_blank">Check us out →</a></h3>
      </div> */}
      <div style={{ width: '100%' }}>
        <img
          style={{ position: 'fixed', top: '50px', height: '50px', width: '100%', zIndex: '30' }}
          src="/images/pages/homepage/wave1.svg"
          alt="Wave"
        />
      </div>
      <BrowseDestinationsWidget />
      <div style={{ width: '100%', marginTop: '-48px' }}>
        <img
          style={{ width: '100%', height: '50px' }}
          src="/images/pages/homepage/wave-wh.svg"
          alt="White wave"
        />
      </div>

      <FluidContainer style={{ marginBottom: '3em' }}>
        <Container>
          <Section style={{ paddingTop: '5em' }}>
            <Row gutter={80} justify="center">
              <Col xs={24} md={10} style={{ textAlign: 'center' }}>
                <Title level={3}>Finally a travel map that syncs with everything</Title>
                <Shaking>
                  <img
                    style={{ maxWidth: '100%', width: '300px' }}
                    src="/images/pages/homepage/sync-partners.png"
                    alt="Sync Partners"
                  />

                  <div>
                    <img
                      data-src="/images/pages/homepage/filter.svg"
                      className="lazyload"
                      style={{ marginBottom: '0.5em' }}
                      alt="Filter icon"
                    />
                  </div>
                </Shaking>

                <BoxContainer
                  style={{
                    margin: '0 auto',
                    position: 'relative',
                    boxShadow: rgTheme.boxShadow.card,
                    background: 'white',
                  }}
                  grey
                >
                  <DottedLine />
                  <CheckIn>
                    <div>
                      <div className="black">Santa Barbara, CA</div>
                      <div className="grey">March 19</div>
                    </div>
                    <Avatar
                      src="/images/shared/partner-logos/concur.jpeg"
                      size="large"
                      alt="Concur logo"
                    />
                  </CheckIn>
                  <CheckIn>
                    <div>
                      <div className="black">Avila Beach, CA</div>
                      <div className="grey">March 21</div>
                    </div>
                    <Avatar
                      src="/images/shared/partner-logos/facebook.png"
                      size="large"
                      alt="Facebook logo"
                    />
                  </CheckIn>
                  <CheckIn>
                    <div>
                      <div className="black">Big Sur, CA</div>
                      <div className="grey">March 23</div>
                    </div>
                    <Avatar
                      src="/images/shared/partner-logos/facebook.png"
                      size="large"
                      alt="Facebook logo"
                    />
                  </CheckIn>
                  <Map src="images/pages/homepage/travel-map.png" alt="My Travel Map" />
                </BoxContainer>

                <CTA>
                  <Link href="/my-travel-map/edit">
                    <a>
                      <Button size="large" color="secondary">
                        My Travel Map
                      </Button>
                    </a>
                  </Link>
                </CTA>
              </Col>
              <SecondCol xs={24} md={10}>
                <Title level={3}>Social and Fun</Title>
                <Title level={4} style={{ color: 'grey' }}>
                  Who&apos;s in Dublin?
                </Title>
                <BoxContainer
                  style={{
                    width: '100%',
                    fontSize: '20px',
                    background: 'white',
                    boxShadow: rgTheme.boxShadow.card,
                    lineHeight: '24px',
                    marginBottom: '2em',
                  }}
                >
                  4 contacts live there. 13 have been. 21 want to go.
                  <div>
                    <Avatar src="/images/pages/homepage/woman1.png" alt="Woman 1" size="large" />
                    <Avatar src="/images/pages/homepage/man2.png" alt="Man 1" size="large" />
                    <Avatar src="/images/pages/homepage/man1.png" alt="Man 2" size="large" />
                    <Avatar src="/images/pages/homepage/woman2.png" alt="Woman 2" size="large" />
                  </div>
                </BoxContainer>

                <Title level={4} style={{ color: 'grey' }}>
                  Compete with friends to be the greatest of all time
                </Title>
                {/* <BoxContainer
                  style={{ width: '100%', maxWidth: '300px', margin: '0 auto', display: 'flex' }}
                >
                  <Avatar
                    size="large"
                    src="/images/shared/logos/padded-goat-logo.png"
                    style={{ backgroundColor: rgTheme.color.primary, marginRight: '1em' }}
                    alt="RoadGoat logo"
                  />
                  <div>
                    Congrats Mary
                    <br />
                    You&apos;re the GOAT
                  </div>
                </BoxContainer> */}
                <BoxContainer
                  style={{
                    width: '100%',
                    fontSize: '20px',
                    backgroundColor: 'white',
                    boxShadow: rgTheme.boxShadow.card,
                    backgroundImage: 'url("/images/pages/homepage/confetti.png")',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    paddingTop: '2em',
                  }}
                >
                  <BoxContainer style={{ margin: '0' }}>
                    <GamifyType style={{ textAlign: 'center', color: 'black' }}>
                      <div>
                        <div>Cities</div>
                        <div>US States</div>
                        <div>Countries</div>
                        <div>Miles</div>
                        <div>Goats Saved</div>
                      </div>
                    </GamifyType>
                    <List bordered={false} size="small">
                      <List.Item>
                        <Avatar
                          src="/images/pages/homepage/woman1.png"
                          size="large"
                          alt="Woman 1"
                        />
                        Mary
                        <span className="grey">The GOAT</span>
                        <span className="count">47</span>
                      </List.Item>
                      <List.Item>
                        <Avatar src="/images/pages/homepage/man1.png" size="large" alt="Man 1" />
                        Larry
                        <span className="grey">Level 4</span>
                        <span className="count">21</span>
                      </List.Item>
                      <List.Item>
                        <Avatar
                          src="/images/pages/homepage/woman2.png"
                          size="large"
                          alt="Woman 2"
                        />
                        Sherry
                        <span className="grey">Level 4</span>
                        <span className="count">20</span>
                      </List.Item>
                      <List.Item>
                        <Avatar src="/images/pages/homepage/man2.png" size="large" alt="Man 2" />
                        Barry
                        <span className="grey">Level 5</span>
                        <span className="count">17</span>
                      </List.Item>
                    </List>
                  </BoxContainer>
                </BoxContainer>
                <CTA>
                  {isAuthenticated ? (
                    <Link href="/leaderboard">
                      <a>
                        <Button size="large" color="secondary">
                          Leaderboard
                        </Button>
                      </a>
                    </Link>
                  ) : (
                    <Link href="/users/login">
                      <a>
                        <Button size="large" color="secondary">
                          Sign Up
                        </Button>
                      </a>
                    </Link>
                  )}
                </CTA>
              </SecondCol>
            </Row>
          </Section>
        </Container>
      </FluidContainer>
      <FluidContainer style={{ backgroundColor: '#f4f8fb', paddingBottom: '2em' }}>
        <Container>
          <Section style={{ paddingTop: '5em' }}>
            <Title level={3}>Highlights from Our Blog</Title>
            <CTA>
              <Link href="/blog">
                <a href="/blog">
                  <Button size="large" color="tertiary" style={{ margin: '0 1em 2em 0' }}>
                    All
                  </Button>
                </a>
              </Link>

              <Link href="/blog/tag/interactive/">
                <a>
                  <Button size="large" color="tertiary" style={{ margin: '0 1em 2em 0' }}>
                    Interactive
                  </Button>
                </a>
              </Link>

              <Link href="/blog/tag/food/">
                <a>
                  <Button size="large" color="tertiary" style={{ marginBottom: '2em' }}>
                    Food
                  </Button>
                </a>
              </Link>
            </CTA>
            <Row justify="center" align="center" gutter={[42, 42]}>
              <Feed />
            </Row>
          </Section>
        </Container>

        {/* <Container body style={{ textAlign: 'center' }}>
          <Title level={2}>Now get on the road, Goat! </Title>
          <img style={{ maxWidth: '400px' }} src="/images/goats-on-the-road.png" alt="Goats on the road"/>
        </Container> */}
      </FluidContainer>
    </Layout>
  );
};

Index.propTypes = {
  pathname: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  user: PropTypes.object,
};

Index.getInitialProps = async ({ store }) => {
  // fetch current user test
  // const awaitUser = await store.dispatch(fetchCurrentUserProfile());
  // set current user state test
  const { user } = store.getState();

  return {
    user,
  };
};

Index.pageId = 'homepage';

export default Index;
